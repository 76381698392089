var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-accordion-custom',
		'w-5col >=768:w-8col max-w-full mx-auto' ],attrs:{"id":("accordion-" + _vm._uid)}},[_c('AccordionLayout',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.title)?_c('LongReadTarget',{attrs:{"container-id":("accordion-" + _vm._uid),"title":_vm.title}},[_c('BaseH2',{staticClass:"text-primary-button",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e()]},proxy:true},{key:"header",fn:function(ref){
	var item = ref.item;
return [_c('BaseH3',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s(item.content.title)}})]}},{key:"panel",fn:function(ref){
	var item = ref.item;
return [_c(_vm.BlockList,{tag:"Component",attrs:{"block-list":item.alias === 'BlockBorgerDkMicroArticle' ||
					item.alias === 'BlockBorgerDkText'
						? [].concat( item )
						: item.content.accordionElements}}),_vm._v(" "),(
					item.alias === 'BlockBorgerDkMicroArticle' ||
					item.alias === 'BlockBorgerDkText'
				)?_c('span',{staticClass:"uppercase text-h8"},[_vm._v("DETTE INDHOLD KOMMER FRA BORGER.DK")]):_vm._e()]}}])},'AccordionLayout',{ title: _vm.title, elements: _vm.elements, id: _vm.id },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }