<template>
	<div
		:id="`accordion-${_uid}`"
		:class="[
			'c-block-list-accordion-custom',
			'w-5col >=768:w-8col max-w-full mx-auto',
		]"
	>
		<AccordionLayout v-bind="{ title, elements, id }">
			<template #title>
				<LongReadTarget
					v-if="title"
					:container-id="`accordion-${_uid}`"
					:title="title"
				>
					<BaseH2
						tag="h2"
						class="text-primary-button"
						v-text="title"
					/>
				</LongReadTarget>
			</template>

			<template #header="{ item }">
				<BaseH3 tag="h3" v-text="item.content.title"></BaseH3>
			</template>

			<template #panel="{ item }">
				<Component
					:is="BlockList"
					:block-list="
						item.alias === 'BlockBorgerDkMicroArticle' ||
						item.alias === 'BlockBorgerDkText'
							? [...item]
							: item.content.accordionElements
					"
				/>
				<span
					v-if="
						item.alias === 'BlockBorgerDkMicroArticle' ||
						item.alias === 'BlockBorgerDkText'
					"
					class="uppercase text-h8"
					>DETTE INDHOLD KOMMER FRA BORGER.DK</span
				>
			</template>
		</AccordionLayout>
	</div>
</template>

<script>
import AccordionLayout from '~/components/shared/AccordionLayout';
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';

const MainBlockList = () => import('~/components/main/BlockList');
const SubsiteBlockList = () => import('~/components/subsite/BlockList');

export default {
	name: 'BlockListAccordionCustom',

	components: {
		AccordionLayout,
		LongReadTarget,
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		id: {
			type: String,
			required: false,
		},
		elements: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		BlockList() {
			const map = {
				main: MainBlockList,
				subsite: SubsiteBlockList,
			};
			return map[this.$store.state.site.alias] || MainBlockList;
		},
	},
};
</script>
